import { PageHeading } from '../../../layout/PageHeading'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { formatMoney, setDocumentTitle } from '../../../../lib/utils'
import { Fragment, useEffect, useState } from 'react'
import { authFetch } from '../../../../providers/AuthProvider'
import {
  CalculatedPrice,
  PackageWithTestsAndBiomarkers,
  PackagesWithTestsAndBiomarkersSchema,
  ProductWithTestsAndBiomarkersSelected,
  TestWithBiomarkers,
  TestWithBiomarkersSelected,
  TestsWithBiomarkersSchema,
} from '../../../../lib/validators'
import PageLoader from '../../../layout/PageLoader'
import { TestCustomisationItem } from '../../../test/TestCustomisationItem'
import TextInputField, { onChangeInterface } from '../../../form/TextInputField'

import { Button } from '../../../form/button/Button'
import { FilterButton } from '../../../layout/FilterButton'
import { PackageCustomisationItem } from '../../../test/PackageCustomisationItem'
import ProgressNavigation from '../../../layout/ProgressNavigation'
// import { RadioGroup } from '../../../form/RadioGroup'
import Checkbox from '../../../form/Checkbox'
import { Modal } from '../../../layout/Modal'
import {
  calculatePrice,
  saveOrganisationTestPackage,
} from '../../../../lib/organisation'
import { Textarea } from '../../../form/Textarea'
import { getBundlesUrl, getCreateTestBundleUrl } from '../../../../lib/routes'
import OrgPage from '../../../OrgPage'
import { SendPackageModal } from './modals/SendPackageModal'
import PriceSummary from '../../../organisation/PriceSummary'
import TestBuilderFooter from '../../../organisation/TestBuilderFooter'

interface Category {
  id: string
  name: string
}

export const defaultPriceObject: CalculatedPrice = {
  testsTotal: 0,
  subTotal: 0,
  grandTotal: 0,
  grandTotalIncTax: 0,
  discounts: [],
  calculating: false,
  fees: [],
  tests: [],
}

export function OrgCreateTest() {
  const [loading, setLoading] = useState(true)
  const [tests, setTests] = useState<TestWithBiomarkersSelected[]>([])
  const [packages, setPackages] = useState<
    ProductWithTestsAndBiomarkersSelected[]
  >([])
  const [filteredTests, setFilteredTests] = useState<
    TestWithBiomarkersSelected[]
  >([])

  const [filteredPackages, setfilteredPackages] = useState<
    ProductWithTestsAndBiomarkersSelected[]
  >([])
  const [totalPrice, setTotalPrice] =
    useState<CalculatedPrice>(defaultPriceObject)
  const [hasTestOrPackageSelected, setHasTestOrPackageSelected] =
    useState(false)
  const [selectedTest, setSelectedTest] =
    useState<TestWithBiomarkersSelected | null>()
  const [selectedPackage, setSelectedPackage] =
    useState<PackageWithTestsAndBiomarkers | null>()
  const [openTestModal, setOpenTestModal] = useState(false)
  const [openPackageModal, setOpenPackageModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isFiltering, setIsFiltering] = useState(false)
  const { org, theme } = useOrganisationContext()
  const [genderFilter, setGenderFilter] = useState<string[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])
  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [bundleName, setBundleName] = useState('')
  const [bundleDescription, setBundleDescription] = useState('')

  // const [bundleGender, setBundleGender] = useState<'m' | 'mf' | 'f'>('mf')
  const bundleGender = 'mf'
  const [shouldSave, setShouldSave] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [productId, setProductId] = useState<string>('')
  const [categories, setCategories] = useState<Category[]>()
  const [calculatePriceController, setCalculatePriceController] = useState<
    AbortController | undefined
  >()

  setDocumentTitle(`Package Creation | ${org && org?.name}`, 'BRANDED')

  // lets fetch the tests

  const getTestsAndProducts = async (
    organisationUuid: string
  ): Promise<[TestWithBiomarkers[], PackageWithTestsAndBiomarkers[]]> => {
    // console.log("we have a profile", profile);
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
          tests {
            id
            name
            shortName
            price
            reviewFee
            standalone
            priceCategory
            biomarkers {
              id
              name
              commonName
              description
              unit
              prefix
              suffix
              category {
                id
                name
              }
            }
          }
          products(organisationUuid: "${organisationUuid}" templatesOnly:true) {
            id
            name
            description
            internal
            createdAt
            price
            tests {
              id
              name
              shortName
              price
              reviewFee
              priceCategory
              standalone
              biomarkers {
                id
                name
                commonName
                description
                unit
                prefix
                suffix
                category {
                  id
                  name
                }
              }
            }
          }
        }`,
      }),
    })
    const data = result.data

    const parsedData = TestsWithBiomarkersSchema.parse(data.data.tests)
    const parsedPackages = PackagesWithTestsAndBiomarkersSchema.parse(
      data.data.products
    )

    return [parsedData, parsedPackages]
  }

  useEffect(() => {
    async function getTestsFromApi(organisationUuid: string) {
      const [fetchedTests, fetchedPackages] =
        await getTestsAndProducts(organisationUuid)

      setTests(
        fetchedTests.map((test) => ({
          ...test,
          checked: false,
          price: test.price,
        }))
      )

      const cats: Record<string, Category> = {}

      fetchedTests
        .map((test) => test.biomarkers)
        .map((biomarkers) => {
          // console.log('the item is', biomarker)
          biomarkers?.map((biomarker) => {
            if (biomarker.category) {
              cats[biomarker.category.id] = {
                name: biomarker.category?.name,
                id: biomarker.category?.id,
              }
            }
          })
        })

      console.log('categories', cats)

      // fetchedTests.map((test) => {
      //   test.biomarkers?.map(
      //     (biomarker) =>
      //       (biomarker.category?.id &&  cats[biomarker.category.id] = {
      //         name: biomarker.category?.name,
      //         id: biomarker.category?.id,
      //       })
      //   )
      // })
      setCategories(Object.values(cats))

      setPackages(
        fetchedPackages.filter((pack) => {
          if (pack.tests && pack.tests.length > 1) {
            return {
              ...pack,
              checked: false,
            }
          }
          return false
        })
      )
      setLoading(false)
    }
    if (org) {
      getTestsFromApi(org.uuid)
    }
  }, [org])

  const onChange = (data: onChangeInterface) => {
    const newState = tests.map((test) => {
      if (
        test.id === data.name &&
        (data.value === true || data.value === false)
      ) {
        return { ...test, checked: data.value }
      }
      return test
    })
    setTests(newState)
  }
  const onSelectPackage = (data: onChangeInterface) => {
    const newState = packages.map((pack) => {
      if (
        pack.id === data.name &&
        (data.value === true || data.value === false)
      ) {
        return { ...pack, checked: data.value }
      }
      return pack
    })
    setPackages(newState)
  }

  const onInfoClick = (test: TestWithBiomarkersSelected) => {
    setSelectedTest(test)
    setOpenTestModal(true)
  }
  const onProductInfoClick = (product: PackageWithTestsAndBiomarkers) => {
    setSelectedPackage(product)
    setOpenPackageModal(true)
  }

  useEffect(() => {
    let total = 0
    tests.forEach((test) =>
      test.checked && test.price ? (total += test.price) : 0
    )
    const testsPicked: string[] = []
    tests.map((test) => test.checked && testsPicked.push(test.id))
    setTotalPrice((prev) => ({
      ...prev,
      calculating: true,
    }))
    if (calculatePriceController) {
      console.log('clear the old controller if we have one')
      calculatePriceController.abort()
    }
    const abortSignal = new AbortController()
    setCalculatePriceController(abortSignal)
    calculatePrice(testsPicked, abortSignal).then((price) => {
      if (price === null) {
        setTotalPrice({
          ...defaultPriceObject,
          calculating: false,
        })
      } else {
        setTotalPrice({
          ...price,
          calculating: false,
        })
      }
    })
    // setTotalPrice(total)
  }, [tests])

  // not used at the moment as its buggy
  // const onRemove = (testId: string) => {
  //   setTests(
  //     tests.map((test) =>
  //       testId === test.id ? { ...test, checked: false } : test
  //     )
  //   )
  // }

  useEffect(() => {
    // fiter packages here
    if (step === 1) {
      let filtered: PackageWithTestsAndBiomarkers[] = packages
      if (searchText || categoryFilter.length > 0 || genderFilter.length > 0) {
        setIsFiltering(true)
        if (searchText) {
          filtered = packages.filter((pack) => {
            if (
              pack.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            ) {
              return true
            }
            if (
              pack.description &&
              pack.description
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) !== -1
            ) {
              return true
            }
            if (pack.tests) {
              for (let b = 0; b < pack.tests?.length; b += 1) {
                if (
                  pack.tests[b].name
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
                ) {
                  // console.log('we matched a biomarker', pack.biomarkers[b].name)
                  return true
                }
              }
            }
            return false
          })
        }
        // Fix this in future
        if (categoryFilter.length > 0) {
          filtered = filtered.filter((pack) => {
            for (const catFilter in categoryFilter) {
              return (
                pack.name
                  .toLowerCase()
                  .indexOf(categoryFilter[catFilter].toLowerCase()) !== -1
              )
            }
          })
        }
        setfilteredPackages(filtered)
      } else {
        setFilteredTests([])
        setIsFiltering(false)
      }
    } else if (step === 2) {
      // filter tests here

      let filtered: TestWithBiomarkersSelected[] = tests
      console.log('filtering => ', searchText, categoryFilter, genderFilter)
      if (searchText || categoryFilter.length > 0 || genderFilter.length > 0) {
        setIsFiltering(true)
        if (searchText) {
          console.log('lets filter the tests now.')
          filtered = tests.filter((test) => {
            if (
              test.name.toLowerCase().indexOf(searchText.toLowerCase()) !==
                -1 ||
              test.shortName.toLowerCase().indexOf(searchText.toLowerCase()) !==
                -1
            ) {
              console.log('we matched', test.name, test)
              return true
            }
            if (test.biomarkers) {
              for (let b = 0; b < test.biomarkers?.length; b += 1) {
                console.log()
                if (
                  test.biomarkers[b].name
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1 ||
                  test.biomarkers[b].commonName
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
                ) {
                  console.log('we matched a biomarker', test.biomarkers[b].name)
                  return true
                }
              }
            }
            return false
          })
        }

        // Fix this in future
        if (categoryFilter.length > 0) {
          filtered = filtered.filter((test) => {
            for (const catFilter in categoryFilter) {
              if (!test.biomarkers) {
                return false
              }
              for (let i = 0; i < test.biomarkers?.length; i += 1) {
                if (
                  test.biomarkers[i].category &&
                  test.biomarkers[i].category?.id === categoryFilter[catFilter]
                ) {
                  return true
                }
              }
            }
            return false
          })
        }
        console.log('the filtered tests', filteredTests)

        setFilteredTests(filtered)
      } else {
        setFilteredTests([])
        setIsFiltering(false)
      }
    }
  }, [searchText, tests, genderFilter, categoryFilter, step])

  const filterClicked = (data: onChangeInterface) => {
    console.log('filter clicked', data)
    if (data.name.indexOf('gender') === 0) {
      if (data.value === true) {
        const existingFilters = [...genderFilter, data.name as string]
        setGenderFilter([...new Set(existingFilters)])
      } else {
        const existingFilters = new Set(genderFilter)
        existingFilters.delete(data.name)
        setGenderFilter([...existingFilters])
      }
    }
    if (data.name.indexOf('category') === 0) {
      const cleanFilterName = (data.name as string).replace('category-', '')
      if (data.value === true) {
        const existingFilters = [...categoryFilter, cleanFilterName]
        setCategoryFilter([...new Set(existingFilters)])
      } else {
        const existingFilters = new Set(categoryFilter)
        existingFilters.delete(cleanFilterName)
        setCategoryFilter([...existingFilters])
      }
    }
  }

  useEffect(() => {
    const checkedTestLength = tests.filter((item) =>
      item.checked ? true : false
    ).length
    const checkedPackageLength = packages.filter((item) =>
      item.checked ? true : false
    ).length
    setHasTestOrPackageSelected(
      checkedTestLength + checkedPackageLength > 0 ? true : false
    )
  }, [packages, tests])

  useEffect(() => {
    if (step === 2) {
      const testsCopy = [...tests]
      // lets make sure we mark the tests selected as ticked
      packages.map((item) => {
        if (item.checked && item.tests) {
          console.log('we selected this one', item)
          item.tests.map((test) => {
            for (const t in tests) {
              if (tests[t].id === test.id) {
                testsCopy[t].checked = true
              }
            }
          })
        }
      })
      setTests(testsCopy)
      // console.log('the new tests are', testsCopy)
      // setTimeout(() => {

      // }, 200)
    }
  }, [step])

  const clearStateBetweenSteps = () => {
    setIsFiltering(false)
    setSearchText('')
    setGenderFilter([])
    setCategoryFilter([])
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const continueToStep2 = () => {
    clearStateBetweenSteps()
    setStep(2)
  }
  const skipToStep2 = () => {
    clearStateBetweenSteps()
    // clear selected filters
    setPackages((prev) => {
      prev.forEach((item) => (item.checked = false))
      return prev
    })
    setStep(2)
  }
  const continueToStep3 = () => {
    clearStateBetweenSteps()
    // clear selected filters
    setStep(3)
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!org) {
      throw new Error('You need an organisation to create packages.')
    }

    // do the save here.
    // saveForReuse
    setIsSaving(true)
    const orgPackage = await saveOrganisationTestPackage(org.uuid, {
      tests: tests.filter((t) => t.checked).map((i) => i.id),
      gender: bundleGender,
      name: bundleName,
      internal: !shouldSave,
      description: bundleDescription || '',
      price: totalPrice ? totalPrice.grandTotal : 0, //   + is to coerce it back to a float
    })
    setProductId(orgPackage.id)
    setIsSaving(false)

    setOpenInviteModal(true)

    // navigate(getBundleUrl(org?.id, orgPackage.id))

    // clearStateBetweenSteps()
    // setStep(3)
  }
  const getPageTitle = () => {
    switch (step) {
      case 1:
        return 'Select from template'
      case 2:
        return 'Handpick your tests'
      case 3:
        return 'Complete your bundle of tests'
    }
  }
  const getPageDescription = () => {
    switch (step) {
      case 1:
        return 'You can start by selecting a template below, or skip to begin hand picking tests for your package.'
      case 2:
        return 'Now you can add individual tests to your package.'
      case 3:
        return 'Finalise how you will use your test.'
    }
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          tabs={[
            {
              name: 'Tests',
              href: getBundlesUrl(org?.id),
            },
            {
              name: `Create New`,
              href: getCreateTestBundleUrl(org?.id),
            },
          ]}
          className="pb-20"
        >
          {loading && <PageLoader theme={org?.theme || undefined}></PageLoader>}
          {!loading && (
            <PageSection>
              <form onSubmit={handleSubmit}>
                <div className="">
                  <PageHeading
                    title={`Step ${step}: ${getPageTitle()}`}
                    description={`${getPageDescription()}`}
                  >
                    <div className="mt-4 mb-4">
                      <ProgressNavigation
                        currentStep={step - 1}
                        steps={[
                          { name: 'Select a template' },
                          { name: 'Refine your package' },
                          { name: 'Finalise the details' },
                        ]}
                      ></ProgressNavigation>
                    </div>
                    {step === 1 && (
                      <Fragment>
                        <Button
                          label="Skip"
                          onClick={() => skipToStep2()}
                          classNames="mr-2"
                          theme={theme || undefined}
                        ></Button>
                      </Fragment>
                    )}
                  </PageHeading>
                </div>
                {step === 3 && (
                  <div>
                    <PageHeading title="Give your bundle a name">
                      <TextInputField
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name of your new test bundle"
                        handleChange={(o) => setBundleName(o.strValue)}
                        required={true}
                        disabled={isSaving}
                      ></TextInputField>
                    </PageHeading>
                    <PageHeading title="Add a description">
                      <Textarea
                        id="description"
                        name="description"
                        handleChange={(o) => setBundleDescription(o.strValue)}
                        required={false}
                        disabled={isSaving}
                        value={bundleDescription}
                      ></Textarea>
                    </PageHeading>

                    <PageHeading
                      title="Would you like to save this bundle as a template?"
                      size="lg"
                    >
                      <Checkbox
                        id="bundle-save-mode"
                        name="bundle-save-mode"
                        type="checkbox"
                        className="w-6 h-6"
                        disabled={isSaving}
                        checkboxLabel="Yes, I would like to save this bundle as a template."
                        onChange={(o) => {
                          setShouldSave(o.strValue === 'true' ? true : false)
                        }}
                      ></Checkbox>
                    </PageHeading>

                    {/* {shouldSave && (
                      <Fragment>
                        <div className="mt-6 mb-6">
                          <label className="block text-sm font-medium leading-6 text-gray-dark">
                            Which gender is this bundle targeted at?
                          </label>
                          <RadioGroup
                            name="gender"
                            disabled={isSaving}
                            options={[
                              ['m', 'Male'],
                              ['mf', 'Both'],
                              ['f', 'Female'],
                            ]}
                            selected="mf"
                            theme={theme || undefined}
                            handleChange={(o) => {
                              console.log('got a change', o)
                              setBundleGender(
                                o.strValue === 'm'
                                  ? 'm'
                                  : o.strValue === 'f'
                                    ? 'f'
                                    : 'mf'
                              )
                            }}
                          ></RadioGroup>
                        </div>
                        <hr className="dark:border-gray-semi-dark mb-6" />
                      </Fragment>
                    )} */}
                  </div>
                )}
                {(step === 1 || step === 2) && (
                  <Fragment>
                    {!(packages.length === 0 || tests.length === 0) && (
                      <div className="pb-2">
                        <TextInputField
                          id="search"
                          name="search"
                          type="search"
                          required={true}
                          value={searchText}
                          placeholder="Search for test or biomarker"
                          handleChange={(data: onChangeInterface) => {
                            setSearchText(data.value as string)
                          }}
                        />
                      </div>
                    )}
                    <div className="pb-4">
                      {/* {step === 1 && (
                        <Fragment>
                          <FilterButton
                            key={`filter-gender-male`}
                            label="Male"
                            id="gender-male"
                            name="gender-male"
                            checked={genderFilter.indexOf('gender-male') !== -1}
                            onChange={filterClicked}
                          ></FilterButton>
                          <FilterButton
                            key={`filter-gender-female`}
                            label="Female"
                            id="gender-female"
                            name="gender-female"
                            checked={
                              genderFilter.indexOf('gender-female') !== -1
                            }
                            onChange={filterClicked}
                          ></FilterButton>
                        </Fragment>
                      )} */}
                      {step === 2 &&
                        categories &&
                        categories.map((cat) => (
                          <FilterButton
                            key={`filter-${cat.name}`}
                            label={cat.name}
                            id={`category-${cat.id}`}
                            name={`category-${cat.id}`}
                            onChange={filterClicked}
                          ></FilterButton>
                        ))}
                    </div>
                  </Fragment>
                )}

                {org && step === 1 && (
                  <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {!isFiltering &&
                      packages.length > 0 &&
                      packages.map((item) => (
                        <PackageCustomisationItem
                          product={item}
                          key={`test-item-${item.id}`}
                          onChange={onSelectPackage}
                          onInfoClick={onProductInfoClick}
                        ></PackageCustomisationItem>
                      ))}
                    {!isFiltering && packages.length === 0 && (
                      <div>No packages to preselect from.</div>
                    )}
                    {isFiltering && (
                      <Fragment>
                        {filteredPackages.map((item) => (
                          <PackageCustomisationItem
                            product={item}
                            key={`test-item-${item.id}`}
                            onChange={onSelectPackage}
                            onInfoClick={onProductInfoClick}
                            highlight={searchText}
                          ></PackageCustomisationItem>
                        ))}
                        {filteredPackages.length === 0 && (
                          <div>No packages matching current filters</div>
                        )}
                      </Fragment>
                    )}
                  </ul>
                )}
                {org && step === 2 && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {!isFiltering &&
                      tests &&
                      tests.map((item) => (
                        <TestCustomisationItem
                          test={item}
                          checked={item.checked}
                          key={`test-item-${item.id}`}
                          onChange={onChange}
                          onInfoClick={onInfoClick}
                        ></TestCustomisationItem>
                      ))}
                    {isFiltering && (
                      <Fragment>
                        {filteredTests.map((item) => (
                          <TestCustomisationItem
                            test={item}
                            checked={item.checked}
                            key={`test-item-${item.id}`}
                            onChange={onChange}
                            onInfoClick={onInfoClick}
                            highlight={searchText}
                          ></TestCustomisationItem>
                        ))}
                        {filteredTests.length === 0 && (
                          <div>No tests matching current filters</div>
                        )}
                      </Fragment>
                    )}
                  </div>
                )}

                <PriceSummary
                  price={totalPrice}
                  showSummary={step === 2 || step === 3}
                  tests={tests}
                  // onRemove={onRemove}
                ></PriceSummary>

                <TestBuilderFooter
                  hasSelectedTest={hasTestOrPackageSelected}
                  price={totalPrice}
                >
                  <Fragment>
                    {step === 1 && (
                      <Fragment>
                        <Button
                          label="Continue"
                          onClick={() => continueToStep2()}
                          color="jade"
                        ></Button>
                      </Fragment>
                    )}
                    {step === 2 && (
                      <Fragment>
                        <Button
                          label="Continue"
                          onClick={() => continueToStep3()}
                          color="jade"
                        ></Button>
                      </Fragment>
                    )}
                    {step === 3 && (
                      <Fragment>
                        <Button
                          label="Finish"
                          color="jade"
                          type="submit"
                          disabled={isSaving}
                        ></Button>
                      </Fragment>
                    )}
                  </Fragment>
                </TestBuilderFooter>
              </form>
            </PageSection>
          )}

          <Modal
            show={openTestModal}
            options={{
              mainActions: [
                {
                  label: 'Dismiss',
                  handler: () => setOpenTestModal(false),
                },
              ],
              title: (selectedTest && selectedTest.name) || '',
              content: (
                <div>
                  Test cost:
                  {(selectedTest &&
                    selectedTest.price &&
                    formatMoney(selectedTest.price / 100, 'aud')) ||
                    'no selected test'}
                </div>
              ),
            }}
            onClose={() => setOpenTestModal(false)}
            theme={theme || undefined}
            size="md"
          ></Modal>
          <Modal
            show={openPackageModal}
            options={{
              mainActions: [
                {
                  label: 'Dismiss',
                  handler: () => setOpenPackageModal(false),
                },
              ],
              title: (selectedPackage && selectedPackage.name) || '',
              content: (
                <div className="mt-2">
                  {selectedPackage?.tests?.map((t) => (
                    <div>
                      <b>{t.name}</b>
                      <div className="text-gray-dark">
                        {t.biomarkers &&
                          t.biomarkers.map((b) => b.name).join(', ')}
                      </div>
                    </div>
                  ))}
                </div>
              ),
            }}
            onClose={() => setOpenPackageModal(false)}
            theme={theme || undefined}
            size="2xl"
          ></Modal>
          <SendPackageModal
            org={org}
            productId={productId}
            theme={theme || undefined}
            show={openInviteModal}
            setShow={setOpenInviteModal}
          ></SendPackageModal>

          {/*
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className={`relative z-10`}
          onClose={() => {
            setOpen(false)
            setTimeout(() => {
              setSelectedTest(null)
            }, 500)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 dark:bg-dark-gray-lighter dark:bg-opacity-75 backdrop-blur-sm bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className={`fixed inset-0 z-10 w-screen overflow-y-auto`}>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg bg-white dark:bg-dark-gray-light text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm`}
                >
                  <div className="bg-white dark:bg-dark-gray-light  px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-center">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 dark:text-white "
                        >
                          {selectedTest?.name}
                        </Dialog.Title>
                        <div className="mt-2 dark:text-white">
                          The description for the selected test goes here.
                        </div>
                        <div className="mt-2">
                          <span className="block text-gray-dark text-md">
                            {formatMoney(selectedTest?.price || 0, 'aud')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6 items-stretch dark:bg-dark-gray-lighter">
                    <Button
                      label="Dismiss"
                      type="button"
                      color="jade"
                      onClick={() => setOpen(false)}
                      theme={theme || undefined}
                    ></Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
        </OrgPage>
      )}
    </Fragment>
  )
}
