import { Fragment, useEffect, useState } from 'react'
import '../../App.css'
import { useUser } from '../../lib/store'
import { Referral } from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'
import { BasicTableGroupProps, BasicTableProps } from '../layout/BasicTable'
import { Link } from 'react-router-dom'
import { resultUrl } from '../../lib/routes'
import PageSection from '../layout/PageSection'
import {
  downloadFile,
  getNiceDate,
  months,
  setDocumentTitle,
} from '../../lib/utils'
import Page from '../Page'
import { Button } from '../form/button/Button'
import { EmptyState } from '../layout/EmptyState'
import Clipboard from '../../../public/img/clipboard.svg'
import PageLoader from '../layout/PageLoader'
import { BuyTestButton } from '../form/button/BuyTestButton'
import { getTestsData } from '../../lib/referrals'

export default function TestsPage() {
  const [tests, setTests] = useState<Referral[]>([])
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()
  const [profile, setProfile] = useState('')
  const user = useUser()
  const [loading, setLoading] = useState(true)

  setDocumentTitle('Referrals')

  // if (!user?.profileUuid) {
  //   throw new Error("Missing Profile");
  // }

  // console.log("we loaded the tests page.");

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user.profileUuid)
    }
    if (profile) {
      getTestsData(profile).then((referrals) => {
        setTests(referrals)
        setLoading(false)
      })
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile])

  useEffect(() => {
    // set the profile to be the first one
    // console.log("we are here, the tests should have updated", tests);
    if (tests) {
      massageTestDataIntoTable(tests)
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [tests])

  // const getStatusTagColor = (status: string): StatusTagColors => {
  //   if (status.toUpperCase() === "PENDING_VERIFICATION") {
  //     return StatusTagColors.ORANGE;
  //   } else if (status.toUpperCase() === "COMPLETE") {
  //     return StatusTagColors.GREEN;
  //   } else if (status.toUpperCase() === "CREATED") {
  //     return StatusTagColors.BLUE;
  //   } else if (status.toUpperCase() === "ISSUED") {
  //     return StatusTagColors.YELLOW;
  //   }
  //   return StatusTagColors.RED;
  // };

  const massageTestDataIntoTable = (referrals: Referral[]) => {
    const result = []
    const resultsByYear: Record<string, BasicTableGroupProps> = {}
    // console.log("referrals", referrals);
    for (const i in referrals) {
      const d = new Date(referrals[i].createdAt)
      const year = d.getFullYear()

      // if (year !== currentYear) {
      if (!resultsByYear[year]) {
        resultsByYear[year] = {
          groupLabel: year.toString(),
          rows: [],
        }
      }
      // console.log("files", referrals[i].fileUuids);
      const mainFile = referrals[i].fileUuids?.pop()
      resultsByYear[year].rows.push([
        `${referrals[i].testName} referral`,
        `${getNiceDate(d.getDate())} ${
          months[d.getMonth()]
        } ${d.getFullYear()}`,
        referrals[i].reference,
        referrals[i].status === 'COMPLETE' &&
        referrals[i].resultUuid !== null ? (
          <Link
            className="hover:text-bgt-primary"
            to={`${resultUrl
              .replace(':uuid', referrals[i].resultUuid!)
              .replace('/*', '')}`}
          >
            <Button color="jade" type="button" label="View Results"></Button>
          </Link>
        ) : (
          mainFile && (
            <button
              className="cursor-pointer text-blood hover:underline"
              title="Download Referral"
              onClick={() => downloadFile(user, mainFile, profile)}
            >
              Download Referral
            </button>
          )
        ),
      ])
    }
    const keys = Object.keys(resultsByYear)
    keys.sort().reverse()
    for (const y in keys) {
      result.push(resultsByYear[keys[y]])
    }
    if (result.length > 0) {
      setTableData({
        columns: ['Name', 'Date Purchased', 'Reference', ''],
        data: result,
      })
    }
  }

  const colClasses = [
    'text-left col-span-3 sm:col-span-4 border-b-1 border-gray-dark content-center pt-4 rounded-tl-lg rounded-bl-lg', //name
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-2 content-center', //reference
    'text-left text-left sm:text-center text-gray-semi-dark col-span-3 sm:col-span-3 content-center', //purchase date
    'text-left sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3 content-center pb-4 rounded-tr-lg rounded-br-lg', // status
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  return (
    <Page>
      {!loading && tableData && tableData.data && (
        <PageSection>
          <PageHeading title="Referrals"></PageHeading>

          <div
            key={`test-table`}
            className="grid grid-cols-3 sm:grid-cols-12 mt-10"
          >
            {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
            >
              {tableData.columns[0]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
            >
              {tableData.columns[1]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
            >
              {tableData.columns[2]}
            </div>
            <div
              className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
            >
              {tableData.columns[3]}
            </div>

            {tableData.data.map((group, g) => (
              <Fragment key={`group-fragment-${g}`}>
                {tableData.data.length > 1 && (
                  <div
                    key={`group-label-${g}`}
                    className={`px-2 py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-12 text-sm text-gray-semi-dark  bg-gray-light dark:bg-dark-gray-light font-bold dark:shadow-lg`}
                  >
                    {group.groupLabel}
                  </div>
                )}
                {group.rows.map((row, k) =>
                  row.map((item, i) => (
                    <div
                      key={`item-row-${k}-${i}`}
                      className={`py-1 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black dark:text-white  ${
                        k % 2 === 1
                          ? 'bg-gray-light dark:bg-dark-gray-light'
                          : ''
                      } ${colClasses[i]} ${
                        i !== row.length - 1
                          ? ''
                          : 'last-row sm:border-none border-b border-gray '
                      } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                    >
                      <div className="inline-block align-middle">{item}</div>
                    </div>
                  ))
                )}
              </Fragment>
            ))}
          </div>
        </PageSection>
      )}
      {!loading && !tableData && (
        <EmptyState
          image={Clipboard}
          title="Referrals: Your Health Ally's High Five!"
          alt="Clipboard Image"
        >
          No referrals just yet? When your doc gives the nod, we'll be on the
          ball! Need to nab a test?{' '}
          <a
            target="bgt-store"
            className="text-blood hover:underline"
            href={`${import.meta.env.VITE_SHOP_URL}/blood-tests`}
          >
            Order one now
          </a>{' '}
          and we'll team up with your health heroes to get your wellness journey
          on the roll!
          <div className="mt-8">
            <BuyTestButton></BuyTestButton>
          </div>
        </EmptyState>
      )}
      {loading && <PageLoader></PageLoader>}
    </Page>
  )
}
