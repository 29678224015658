import { UserAndProfile, User, UserAndProfileSchema } from '../lib/validators'
import { authFetch } from '../providers/AuthProvider'
import { actions } from './store'
// import { useEffect, DependencyList, useCallback, useRef } from "react";

export function parseLocalStorageItem(
  key?: string | null | undefined,
  coerceType?: string
): string | number | undefined {
  if (!key) {
    return undefined
  }
  const value = localStorage.getItem(key)
  const parsedValue =
    value === '' ||
    value === 'undefined' ||
    value === undefined ||
    value === null
      ? undefined
      : value
  if (parsedValue === undefined) {
    return parsedValue
  }
  if (coerceType && coerceType === 'int') {
    return parseInt(parsedValue, 10)
  }
  return parsedValue
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isOfAge(dobStr: string, age: number) {
  // dates are all converted to date objects
  const dob = new Date(dobStr)
  const today = new Date()
  const max_dob = new Date(
    today.getFullYear() - age,
    today.getMonth(),
    today.getDate()
  )
  return max_dob.getTime() > dob.getTime()
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const getUserAndProfileData = async (): Promise<UserAndProfile> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          me {
            email
            firstName
            lastName
            image
            imageCrop {
              crop {
                x
                y
                height
                width
                unit
              }
              url
            }
            emailVerified
            userSetPassword
            acceptedLatestTerms
            disabled
            uuid,
            isAdministrator
            profileUuid
            missingProfileData
            profileInitComplete
            activeOrganisation
            organisations {
              uuid
              id
              name
              type
              resultsEmail
              prettyResultsEmail
              billingFailedEmail
              referralsEmail
              collectionCentreUrl
              createdAt
              updatedAt
              apiTermsAccepted
              orgTermsAccepted
              abn
              entityName
              theme {
                logo
                logoOriginal {
                  url
                  crop {
                    x
                    y
                    height
                    width
                    unit
                  }
                }
                logoSquare
                logoSquareOriginal {
                  url
                  crop {
                    x
                    y
                    height
                    width
                    unit
                  }
                }
                primaryColor
                secondaryColor
                headerBarBackgroundColor
                buttonColor
                buttonHoverColor
                buttonTextColor
                linkColor
              }
            }
          }}`,
    }),
  })
  const data = result.data
  const parsedData = UserAndProfileSchema.parse(data.data.me)
  return parsedData
}

export const refreshCurrentUser = async (): Promise<UserAndProfile> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const userData = await getUserAndProfileData()
    actions.setUser(userData)
    actions.setEmailVerified(userData.emailVerified)
    actions.setMissingProfileData(userData.missingProfileData)
    actions.setIsProfileComplete(userData.profileInitComplete)
    actions.setOrganisations(userData.organisations)

    // lets pull this from local storage for now
    actions.updateCurrentOrganisation()
    // delay it a bit
    setTimeout(() => {
      resolve(userData)
    }, 300)
  })

  // just delay the callback a bit.
}

export const downloadFile = async (
  user: User | null,
  fileUuid: string,
  profileUuid: string,
  organisationUuid?: string
): Promise<void> => {
  // setTimeout(async () => {
  if (!user) {
    throw new Error('Missing user')
  }
  // console.log("we have a profile", profileUuid);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          fileDownloadUrl(uuid: "${fileUuid}" profileUuid: "${profileUuid}" ${organisationUuid ? `organisationUuid: "${organisationUuid}"` : ''}) {
            url
          }}`,
    }),
  })
  const data = result.data
  // console.log("result json is", data);
  if (data && data && data.data.fileDownloadUrl) {
    window.location.href = data.data.fileDownloadUrl.url
  }
  return
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getNiceDate = (d: number): string => {
  if ([1, 21, 31].indexOf(d) !== -1) {
    return `${d}st`
  }
  if ([2, 22].indexOf(d) !== -1) {
    return `${d}nd`
  }
  if ([3, 23].indexOf(d) !== -1) {
    return `${d}rd`
  }
  return `${d}th`
}

export function humanFileSize(
  bytes: number,
  si: boolean = true,
  dp: number = 1
) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

export const getPrettyDate = (d?: Date): string => {
  if (!d) {
    return ''
  }
  return `${d.getUTCFullYear()}-${
    d.getMonth() < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
  }-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`
}
export const getPrettyDateAndTime = (d?: Date): string => {
  if (!d) {
    return ''
  }
  return `${getPrettyDate(d)}T${
    d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
  }:${d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()}`
}

function ordinalSuffixOf(i: number, withMarkup: boolean = false) {
  const j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + (withMarkup ? '<sup>st</sup>' : 'st')
  }
  if (j === 2 && k !== 12) {
    return i + (withMarkup ? '<sup>nd</sup>' : 'nd')
  }
  if (j === 3 && k !== 13) {
    return i + (withMarkup ? '<sup>rd</sup>' : 'rd')
  }
  return i + (withMarkup ? '<sup>th</sup>' : 'th')
}

export const getUserPrettyDate = (
  d?: Date,
  withMarkup: boolean = false
): string => {
  if (!d) {
    return ''
  }
  return `${ordinalSuffixOf(d.getDate(), withMarkup)} ${
    months[d.getMonth()]
  } ${d.getUTCFullYear()}`
}

export const currentDate = function (): string {
  return getPrettyDate(new Date())
}

export const dateInThePast = function (yearsAgo: number): string {
  const d = new Date()
  d.setFullYear(d.getFullYear() - yearsAgo)
  return getPrettyDate(d)
}

export function formatMoney(n: number, currency: string): string {
  return n.toLocaleString('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
  })
}

export function simpleMdTransform(input?: string | null): string {
  if (!input) {
    return ''
  }
  const lines = input.split('\n')
  const newLines: string[] = []
  for (const l in lines) {
    let line = lines[l]
    // console.log("the line => ", line);
    const h1Matches = line.match(/^# (.*)$/gi)
    if (h1Matches && h1Matches.length > 0) {
      line = `<h1 class="text-lg font-bold text-black/70  dark:text-white sm:text-xl">${h1Matches[0].replace(
        '# ',
        ''
      )}</h1>`
      newLines.push(line)
      continue
    }
    const h2Matches = line.match(/^## (.*)$/gi)
    if (h2Matches && h2Matches.length > 0) {
      line = `<h2 class="text-md font-bold text-black/70  dark:text-white sm:text-lg">${h2Matches[0].replace(
        '## ',
        ''
      )}</h2>`
      newLines.push(line)
      continue
    }
    const h3Matches = line.match(/^### (.*)$/gi)
    if (h3Matches && h3Matches.length > 0) {
      line = `<h3 class="text-md font-bold text-black/70  dark:text-white sm:text-md">${h3Matches[0].replace(
        '### ',
        ''
      )}</h3>`
      newLines.push(line)
      continue
    }
    if (line.trim() === '') {
      newLines.push(`<br />`)
    } else {
      newLines.push(`<p>${line}</p>`)
    }
  }
  // const matches = e.value.toString().match(/^# (.*)$/gi);
  // console.log("matches are", matches);
  return newLines.join('\n')
}

export type CleanTypes = 'BRANDED' | 'CLEAN'

export function setDocumentTitle(
  title: string | null,
  clean?: CleanTypes
): void {
  const baseBrand = `Bloody Good Tests`
  const base =
    clean && clean === 'CLEAN'
      ? ''
      : clean && clean === 'BRANDED'
        ? `Powered by ${baseBrand}`
        : baseBrand
  const delim = base === '' ? '' : ' | '
  if (!title) {
    document.title = `${base}`
  } else {
    document.title = [title, base].join(delim)
  }
  console.log('Set the page title:', document.title)
}

export function capitalizeFirstLetter(s?: string | null): string {
  if (!s) {
    return ''
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export function isEmailValid(email: string): boolean {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi
  )
  const result = emailRegex.exec(email)
  return result && result.length > 0 ? true : false
}

// export function useDebounceEffect(
//   fn: () => void,
//   waitTime: number,
//   deps: DependencyList
// ) {
//   useEffect(() => {
//     const t = setTimeout(() => {
//       fn(...deps);
//     }, waitTime);

//     return () => {
//       clearTimeout(t);
//     };
//   }, deps);
// }

// export function useDebounceEffect(
//   fn: () => void,
//   waitTime: number,
//   deps: DependencyList
// ) {
//   useEffect(() => {
//     const t = setTimeout(() => {
//       // eslint-disable-next-line prefer-spread
//       fn.apply(undefined, deps);
//     }, waitTime);

//     return () => {
//       clearTimeout(t);
//     };
//   }, deps);
// }

// export const useDebounceEffect = (
//   fnc: () => void,
//   deps: DependencyList,
//   delay: number
// ) => {
//   const ref = useRef();

//   useEffect(() => {
//     clearTimeout(ref.current);
//     ref.current = setTimeout(() => {
//       fnc();
//       clearTimeout(ref.current);
//     }, delay);
//   }, [fnc, ...deps, delay]);
// };

// export function useDebounceEffect(
//   effect: () => void,
//   deps: DependencyList,
//   delay = 250
// ) {
//   const callback = useCallback(effect, deps);

//   useEffect(() => {
//     const timeout = setTimeout(callback, delay);
//     return () => clearTimeout(timeout);
//   }, [callback, delay]);
// }
